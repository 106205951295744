import { themes } from "../theme/initThemes";
import layout1Settings from "./Layout1/Layout1Settings";
import layout2Settings from "./Layout2/Layout2Settings";

export const layoutSettings = {
  activeLayout: "layout1", // layout1, layout2
  activeTheme: "purple1", // View all valid theme colors inside theme/themeColors.js
  perfectScrollbar: false,

  themes: themes,
  layout1Settings, // open Layout1/Layout1Settings.js
  layout2Settings, // open Layout2/Layout2Settings.js

  secondarySidebar: {
    show: true,
    open: false,
    theme: "slateDark1", // View all valid theme colors inside theme/themeColors.js
  },
  // Footer options
  footer: {
    show: true,
    fixed: false,
    theme: "slateDark1", // View all valid theme colors inside theme/themeColors.js
  },
};
