import { loginUrl } from "app/api/api_urls";
import { Loading } from "app/components";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { showToast } from "./toast";

const initialState = {
  username: null,
  isInitialised: false,
  isAuthenticated: false,
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (access, refresh, username) => {
  if (access) {
    localStorage.setItem("access", access);
    localStorage.setItem("refresh", refresh);
    localStorage.setItem("username", username);
    axios.defaults.headers.common.Authorization = `Bearer ${access}`;
  } else {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("username");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, username } = action.payload;
      return { ...state, username, isAuthenticated, isInitialised: true };
    }
    case "LOGIN": {
      const { username } = action.payload;
      return { ...state, username, isAuthenticated: true };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, username: null };
    }
    case "REGISTER": {
      const { username } = action.payload;
      return { ...state, isAuthenticated: true, username };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => {},
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const login = async (email, password) => {
    try{

      const { data } = await axios.post(loginUrl, {
        email,
        password,
      });
  
      const { access, refresh } = data;
      setSession(access, refresh, email);
      dispatch({ type: "LOGIN", payload: { username: email } });
      showToast('Logged in successful', 'success');
      setTimeout(() => {
        navigate("/dashboard/orders");
      }, 500);
    }
    catch(error){
      showToast('Something went wrong', 'error');
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("username");
    }
  };

  const register = async (email, username, password) => {
    const { data } = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });

    const { accessToken, user } = data;
    setSession(accessToken);
    dispatch({ type: "REGISTER", payload: { user } });
  };

  const logout = () => {
    setSession(null, null, null);
    dispatch({ type: "LOGOUT" });
    navigate("/session/signin");
  };

  // console.log("jwt", state);
  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("access");
        const refresh = window.localStorage.getItem("refresh");
        const username = window.localStorage.getItem("username");

        if (accessToken && isValidToken(accessToken) &&refresh) {
          setSession(accessToken, refresh, username);

          dispatch({
            type: "INIT",
            payload: { isAuthenticated: true, username },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: { isAuthenticated: false, username: null },
          });
        }
      } catch (err) {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, username: null },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) return <Loading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
