import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { studentUrl } from 'app/api/api_urls';
import axios from 'axios';

const initialState = {
  students: [],
  loading: false,
  error: null,
};

export const fetchStudents = createAsyncThunk('students/fetchStudents', async () => {
  const token = localStorage.getItem("access");
  try {
    const response = await axios.get(studentUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }  catch (error) {
      const data = error?.response?.data || error.message;
      const statusCode = error.response?.status;
      const _err = {
        message: data,
        statusCode,
      };
      throw _err;
  }
});


const studentSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.loading = false;
        state.students = action.payload;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const studentReducer = studentSlice.reducer;
