import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ordersUrl } from 'app/api/api_urls';
import axios from 'axios';

const initialState = {
  orders:  {},
  loading: false,
  error: null,
};

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (query) => {
  const token = localStorage.getItem("access");
  const ordersMenuURL = `${ordersUrl}${query}`
  try {
    const response = await axios.get(ordersMenuURL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }  catch (error) {
      const data = error?.response?.data || error.message;
      const statusCode = error.response?.status;
      const _err = {
        message: data,
        statusCode,
      };
      throw _err;
  }
});


const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const orderReducer = orderSlice.reducer;
